import React from "react"
import {graphql, Link, useStaticQuery} from "gatsby"
import Img from "gatsby-image"

import Layout from "../components/layout"
import SEO from "../components/seo"
import expertSupport from "../images/self-help.svg"
import lineGraph from "../images/information.svg"
import medicalReport from "../images/medical-report.svg"
import women from "../images/women.svg"
import {Col, Container, Row, Button} from "react-bootstrap"

const IndexPage = () => {
  const data = useStaticQuery(graphql`
    query {
      knowledge: file(relativePath: { eq: "knowledge-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      smilingWoman: file(relativePath: { eq: "smiling_mature_woman.png" }) {
        childImageSharp {
          fluid(maxWidth: 600) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      telehealth: file(relativePath: { eq: "cbt-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      tracking: file(relativePath: { eq: "tracking-screenshot.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      circles: file(relativePath: { eq: "circles-call.png" }) {
        childImageSharp {
          fluid(maxWidth: 300) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `)
  return (
    <Layout>
      <SEO title="Baushe - Supporting women through life-changing moments"/>
      <section className="bg-gradient">
        <Container>
          <Row className="align-items-center">
            <Col xs="12" sm="6" className="order-1 order-md-0 pb-3 pb-md-0">
              <h1>
                Menopause on <i className="font-weight-bolder">your terms</i>
              </h1>
              <p>
                All you need to experience menopause at your best, in one place.
              </p>
              <div>
                <Button as={Link} to="/assessment" variant="primary" size="lg">Start Assessment</Button>
              </div>
            </Col>
            <Col className="d-flex justify-content-center order-0 order-md-1 mb-5 mb-md-0" xs="12" sm="6">
              <Img className="w-100" fluid={data?.smilingWoman?.childImageSharp?.fluid}/>
            </Col>
          </Row>
        </Container>
      </section>
      <section className="bg-light">
        <Container className="py-5">
          <Row>
            <Col>
              <h2 id="how-it-works">How does it work?</h2>
            </Col>
          </Row>
          <Row className="mt-5">
            <Col xs={6} lg={3}>
              <div className="text-center"><img src={women} width="100" alt="Peer-mentoring Community" /></div>
              <h4 className="text-center mt-3">Community</h4>
              <p>
                Join a real peer mentoring community
                where you'll meet and exchange with
                women on a similar journey
              </p>
            </Col>
            <Col xs={6} lg={3}>
              <div className="text-center"><img src={expertSupport} width="100" alt="Self-help Therapy" /></div>
              <h4 className="text-center mt-3">Guided self-help</h4>
              <p>
                Get in control of your menopause journey through
                evidence based  cognitive behavioral therapy strategies
              </p>
            </Col>
            <Col xs={6} lg={3}>
              <div className="text-center"><img src={lineGraph} width="100" alt="Reliable Information" /></div>
              <h4 className="text-center mt-3">Reliable Information</h4>
              <p>
                Be informed, learn about menopause with
                up-to-date, reliable information
                powered by our expert advisors
              </p>
            </Col>
            <Col xs={6} lg={3}>
              <div className="text-center"><img src={medicalReport} width="100" alt="Health Tracking" /></div>
              <h4 className="text-center mt-3">Health Tracking</h4>
              <p>
                Understand more about your menopause, getting to  know your body and its triggers
              </p>
            </Col>
          </Row>
        </Container>
      </section>
      <section>
        <Container className="py-5">
          <Row>
            <Col>
              <h2 id="features">Features</h2>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="order-1 order-md-0">
              <h3 className="mb-3">
                Be part of our unique <b className="text-primary">peer mentoring community</b>
              </h3>
              <p>
                Meet with fellow women going through a similar stage, to support each other and grow together.
              </p>
              <Button as={Link} className="mt-3" to="/assessment">Join Baushe</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center order-0 order-md-1">
              <Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.circles?.childImageSharp?.fluid}/>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="d-flex justify-content-center">
              <Img style={{ width: '200px'}} className="my-3 my-md-0" fluid={data?.telehealth?.childImageSharp?.fluid}/>
            </Col>
            <Col xs="12" md="6">
              <h3 className="mb-3">
                Manage your symptoms with <b className="text-primary">self-help CBT techniques</b>
              </h3>
              <p>
                Through interactive, fun and personalized CBT exercises, you’ll find yourself in more control.
              </p>
              <Button as={Link} className="mt-3" to="/assessment">Get Started</Button>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="order-1 order-md-0">
              <h3 className="mb-3">
                Access a knowledge bank of <b className="text-primary">reliable information</b>
              </h3>
              <p>
                Get to know the <b>demystified</b> version of what you’re going through, what options are there available for you, and the <b>latest research</b> in a day-to-day language.
              </p>
              <Button as={Link} className="mt-3" to="/assessment">Get started</Button>
            </Col>
            <Col xs="12" md="6" className="d-flex justify-content-center order-0 order-md-1">
              <Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.knowledge?.childImageSharp?.fluid}/>
            </Col>
          </Row>
          <Row className="align-items-center mb-5">
            <Col xs="12" md="6" className="d-flex justify-content-center">
              <Img style={{ width: '200px'}} className="my-3 my-md-0"  fluid={data?.tracking?.childImageSharp?.fluid}/>
            </Col>
            <Col xs="12" md="6">
              <h3 className="mb-3">
                <b className="text-primary">Understand</b> more about your menopause
              </h3>
              <p>
                Get to know your unique health profile by tracking your symptoms and potential triggers. After gathering enough data points, we will provide you with personalized advice and reliable content relevant to you.
              </p>
              <Button as={Link} className="mt-3" to="/assessment">Start today</Button>
            </Col>
          </Row>
        </Container>
      </section>
    </Layout>
  )
}

export default IndexPage
